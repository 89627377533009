






















import Vue from 'vue';
import Backbone from '@/core/Backbone';
import Component from 'vue-class-component';
import router from '@/router';

@Component({
	components: {},
	filters: {}
})
export default class ConfirmPrivacyPolicy extends Vue {
	private hasAgreed = false;

	private async Accept() {
		Backbone.UI.Progress.Show('Kommikerer med serveren...');
		Backbone.State.Organisation.PrivacyPolicyAgreed = true;
		let result = await Backbone.RemoteActions.Other.PutOrganisation(Backbone.State.Organisation);
		Backbone.UI.Progress.Hide();
		if (result.status < 300) {
			router.push('/addresslist');
			return;
		}

		Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', result, 'error', true);
	}
}
